import React,{useState} from 'react'
import { Link } from 'react-router-dom'
import { loadingImg, Searchlight, uploadImgs, userImg } from '../../../utilities/images'
import CustomModal from '../../components/shared/CustomModal'
import TotalAmount from './TotalAmountModal'

const CashModal = () => {
    const [key, setKey] = useState(Math.random());
    const [modalDetail, setModalDetail] = useState({
        show: false,
        title: "",
        flag: ""
    });
    const handleOnCloseModal = () => {
        setModalDetail({
            show: false,
            title: "",
            flag: ""
        });
        setKey(Math.random());
    }
  return (
    <div className='cashModal'>
            <form className='row justify-content-center mb-5' action=''>
                <h2 className='subHeading col-md-8 text-start p-0'>Customer phone number</h2>
                <div className='form-group col-md-8  mt-1 p-0'>
                    <button className='searchSubmit'>
                        <img src={Searchlight} alt='SearchIcon' className='img-fluid' />
                    </button>
                    <input type='text' placeholder='' className='form-control textIndent' value='569 452 4521' />
                </div>
                {/* <span className=''>Customer not in system</span> */}
                <div className='detailsContainer col-md-8'>
                    <div className='customerDetailsArea'>
                        <img src={userImg} alt='userName' className='userImg' />
                        <div className='customerDetails'>
                            <h2 className='subHeading'>Terry Moore</h2>
                            <p className='innerSubtext fw-normal'>803-238-2630</p>
                            <Link href='#' className='innerSubtext fw-normal mt-2 mb-2 d-inline-block' >harryrady@jourrapide.com</Link>
                            <aside>
                                4849 Owagner Lane<br />
                                Seattle, WA 98101
                            </aside>
                        </div>
                    </div>
                </div>
                <div className='col-md-8 text-start'>
                   <form className='customerCreator' action=''>
                    <div className='form-group mb-2'>
                        <label className='labelHeadTxt mb-1' for='First Name'>First name</label>
                        <input type='text' placeholder='First name' className='form-control'/>
                    </div>
                    <div className='form-group mb-2'>
                        <label className='labelHeadTxt mb-1' for='Last name'>Last name</label>
                        <input type='text' placeholder='First name' className='form-control'/>
                    </div>
                    <div className='form-group mb-2'>
                        <label className='labelHeadTxt mb-1' for='Email address'>Email address</label>
                        <input type='mail' placeholder='First name' className='form-control'/>
                    </div>
                   </form>
                </div>
            </form>
            <p className='redirectingText active' style={{cursor: 'pointer'}}
                onClick={() => {
                    setModalDetail({ show: true, flag: 'totalAmountModal', });
                    setKey(Math.random());
                }}
            >Rederecting 
            <img src={loadingImg} alt='loadingImg'  className='ms-3'/></p>

            <CustomModal
                key={key}
                show={modalDetail.show}
                backdrop="static"
                showCloseBtn={false}
                isRightSideModal={false}
                mediumWidth={false}
                checkoutModal={true}
                header={
                    <div className='modalHeader_'>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="common">
                                    <p className="checkout_heading">
                                        {modalDetail.flag === 'updatePriceModal' ?
                                            <>
                                                <span>Update Price</span>
                                            </>
                                            :
                                            (modalDetail.flag === "walletModal"
                                            ?
                                            <span>Customer & Payment</span>
                                            :
                                            (modalDetail.flag === "cashModal"
                                            ?
                                            <span>Customer</span>
                                            :
                                            (modalDetail.flag === "totalAmountModal"
                                            ?
                                            <span>Total amount: $254.60</span>
                                            :
                                            <>
                                                <span>Amount: </span>
                                                <span><sup> $</sup>382.75</span>
                                            </>
                                            )))}
                                    </p>
                                    <span className="crossIcon" onClick={() => handleOnCloseModal()}>
                                        <i class="las la-times" aria-hidden="true"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                child={
                     modalDetail.flag ===  "totalAmountModal" ? <TotalAmount />:
                     ""
                }
                onCloseModal={() => handleOnCloseModal()}
            />
    </div>
  )
}

export default CashModal