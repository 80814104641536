import React from 'react'
import { useHistory } from 'react-router-dom'
import { loadingImg, uploadImgs } from '../../../utilities/images'

const WalletModal = () => {
    const history = useHistory()
  return (
    <div className='walletModal'>
        <div className=''>
            <h2 className='marlboro_heading'>Wallet Id</h2>
            <form className='row justify-content-center mb-5' action=''>
                <div className='form-group col-md-8  mt-2'>
                    <input type='text' placeholder='' className='form-control text-center' value='509 236 2365' />
                </div>
            </form>
            <h2 className='marlboro_heading pt-5 mb-2'>Or Scan</h2>
            <div className='uploadedScanArea'>
                <img src={uploadImgs} alt='uploadedBarcodeImg' className='uploadedImgs' />
            </div>
            <p className='redirectingText active' style={{cursor:'pointer'}}
            onClick={()=>{history.push('/payment-details')}}
            >Rederecting 
            <img src={loadingImg} alt='loadingImg'  className='ms-3'/></p>
        </div>  
    </div>
  )
}

export default WalletModal