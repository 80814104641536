import React from 'react'
import { combackarrow, deliveryOrder } from '../../../utilities/images'
import notification from '../../public/images/notification.png'
import { Link, useHistory } from 'react-router-dom';

const NavbarCommonBack = () => {
    return (
        <div className='navbarHead'>
            <div className='Mainheadingnav'>
                <Link to="#" className='backbtn'><img src={combackarrow} className="img-fluid showImg" alt="" /> Back</Link>
            </div>
            <div className='rightSearch'>
                <Link to="#" class="midRightlinks">
                    <span class="midRightIcons">
                        <img src={notification} class="img-fluid" />
                    </span>
                </Link>
                <form class="searchfom">
                    <input type="text" placeholder="Search.." name="search" />
                    <button type="submit" className='searchbtn'><i class="fa fa-search"></i></button>
                </form>

                <span class="dotIcon"></span>

            </div>
        </div>
    )
}

export default NavbarCommonBack