import React, { useState, useEffect } from 'react';
import PhoneInput from 'react-phone-input-2';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

const Login = () => {
  const history = useHistory();
  const [phoneNo, setPhoneNo] = useState("");
  const [phoneCode, SetPhoneCode] = useState("");

  const onChangePhoneNumber = (value, data, event, formattedValue) => {
    let phoneCode = data.dialCode;
    let phoneNumber = value.slice(data.dialCode.length);
    setPhoneNo(phoneNumber);
    SetPhoneCode(phoneCode);
  }

  const sendOtp = () => {
    if (!phoneNo) {
      toast.error("Please enter phone number");
      return;
    }
    else if (phoneNo && phoneNo.length <= 4) {
      toast.error("Phone number length should be of 5 to 15 digits");
      return;
    }
    else if (phoneNo && !phoneCode) {
      toast.error("Please select your phone code");
      return;
    }
    history.push('/verify-phone');
  }
  return (
    <>
      <div className='digit-group'>
        <h2 className="innerHeading">Verify your phone number</h2>
        <p className="innerSubtext">Please enter your number to phone nubmer</p>
        <div className="form-group input-group">
          <form action="#" name="POST" className='loginDetails'>
            <div id="result">
              <PhoneInput
                country="us"
                // value={phoneNo}
                placeholder="PhoneNo."
                onChange={(value, data, event, formattedValue) => { onChangePhoneNumber(value, data, event, formattedValue) }}
              />
              <span id="valid-msg" className="hide">✓ Valid</span>
              <span id="error-msg" className="hide"></span>
            </div>
          </form>
        </div>
        <div className="bottom-part">
          <Link to="#" className="bottom-btn" onClick={() => { sendOtp() }}>Continue</Link>
        </div>
      </div>
    </>
  )
}

export default Login