import React, { useState } from 'react'
import { Addsquarelight, AddsquarelightDark, analytics, analyticsDark, BrandLogo, calendar, customerD, deliveryOrder, deliveryOrderDark, power, reward, shippingOrder, shippingOrderDark, Subtract, tray, trayDark, wallet, walletDark } from '../../../utilities/images'
import { Link, useHistory } from 'react-router-dom';
const Sidebar = () => {
    const [activeData, setActiveData] = useState("")
    return (
        <aside className="main-sidebar" id="myNav">
            <Link to="/dashboard" className="brandLogo" onClick={() => setActiveData("add")}>
                <img src={BrandLogo} className="img-fluid" alt="" />
            </Link>
            <Link to="#" className="closebtn" ><i className="las la-times"></i></Link>
            <ul className="sidebarMenus navbar_overlay_content_">
                <div className='sidebarStaticMenus'>
                    <li className={activeData == 'add' ? "sidebarItems active" : "sidebarItems"}>
                        <Link to="/dashboard" className="sidebarLinks" onClick={() => setActiveData("add")}>
                            <img src={Addsquarelight} className="img-fluid showImg" alt="" />
                            <img src={AddsquarelightDark} className="img-fluid hoverImg active" alt="" />
                        </Link>
                    </li>
                    <li className={activeData == 'delivery' ? "sidebarItems active" : "sidebarItems"}>
                        <Link to="/delivery" className="sidebarLinks" onClick={() => setActiveData("delivery")}>
                            <img src={deliveryOrder} className="img-fluid showImg" alt="" />
                            <img src={deliveryOrderDark} className="img-fluid hoverImg" alt="" />
                        </Link>
                    </li>
                    <li className={activeData == 'shipping' ? "sidebarItems active" : "sidebarItems"}>
                        <Link to="/shipping" className="sidebarLinks" onClick={() => setActiveData("shipping")}>
                            <img src={shippingOrder} className="img-fluid showImg" alt="" />
                            <img src={shippingOrderDark} className="img-fluid hoverImg" alt="" />
                        </Link>
                    </li>
                    <li className={activeData == 'calendar' ? "sidebarItems active" : "sidebarItems"}>
                        <Link to="calender" className="sidebarLinks" onClick={() => setActiveData("calendar")}>
                            <img src={calendar} className="img-fluid" alt="" />
                        </Link>
                    </li>
                    <li className={activeData == 'analytics' ? "sidebarItems active" : "sidebarItems"}>
                        <Link to="/analytics" className="sidebarLinks" onClick={() => setActiveData("analytics")}>
                            <img src={analytics} className="img-fluid showImg" alt="" />
                            <img src={analyticsDark} className="img-fluid hoverImg" alt="" />
                        </Link>
                    </li>
                    <li className={activeData == 'wallet' ? "sidebarItems active" : "sidebarItems"}>
                        <Link to="/wallet" className="sidebarLinks" onClick={() => setActiveData("wallet")}>
                            <img src={wallet} className="img-fluid showImg" alt="" />
                            <img src={walletDark} className="img-fluid hoverImg" alt="" />
                        </Link>
                    </li>
                    <li className={activeData == 'tray' ? "sidebarItems active" : "sidebarItems"}>
                        <Link to="/drawerCash" className="sidebarLinks" onClick={() => setActiveData("tray")}>
                            <img src={tray} className="img-fluid showImg" alt="" />
                            <img src={trayDark} className="img-fluid hoverImg" alt="" />
                        </Link>
                    </li>
                    <li className={activeData == 'customer' ? "sidebarItems active" : "sidebarItems"}>
                        <Link to="/customers" className="sidebarLinks" onClick={() => setActiveData("customer")}>
                            <img src={customerD} className="img-fluid" alt="" />
                        </Link>
                    </li>
                    <li className={activeData == 'reward' ? "sidebarItems active" : "sidebarItems"}>
                        <Link to="/rewards" className="sidebarLinks" onClick={() => setActiveData("reward")}>
                            <img src={reward} className="img-fluid" alt="" />
                        </Link>
                    </li>
                    <li className={activeData == 'Subtract' ? "sidebarItems active" : "sidebarItems"}>
                        <Link to="/settings" className="sidebarLinks" onClick={() => setActiveData("Subtract")}>
                            <img src={Subtract} className="img-fluid" alt="" />
                        </Link>
                    </li>
                </div>
                <div className='sidbarfixedMenus'>
                    <li className="sidebarItems">
                        <Link to="#" className="sidebarLinks" onClick={() => setActiveData("power")}>
                            <img src={power} className="img-fluid" alt="" />
                        </Link>
                    </li>
                </div>
            </ul>
        </aside>
    )
}
export default Sidebar