import React from 'react';
import Sidebar from './Sidebar';

const DashboardLayout = (props) => {
    return (
        <main>
            <div className="wrapper">
                <Sidebar />
                <div className="rightWrapper">
                    {props.children}
                </div>
                {/* <Footer {...props}/> */}
            </div>
        </main>
    )
}

export default DashboardLayout;