import React, { useState } from 'react';
import { Chart } from "react-google-charts";

const Sales = () => {

    const totalOrderData = [
        ["Week", "Sales"],
        ["Monday", 10],
        ["Tuesday", 70],
        ["Wednesday", 60],
        ["Thursday", 90],
        ["Friday", 30],
        ["Saturday", 50],
        ["Sunday", 40],
    ];

    const totalOrderOptions = {
        isStacked: true,
        height: 300,
        width: 700,
        legend: { position: "top", maxLines: 3 },
        vAxis: { minValue: 0 },
    };

    const totalRevenueData = [
        ["Week", "Store", "Delivery", "Shipping"],
        ["Monday", 1000, 400, 200],
        ["Tuesday", 1170, 460, 250],
        ["Wednesday", 1030, 540, 350],
        ["Thursday", 1030, 40, 350],
        ["Friday", 1030, 590, 350],
        ["Saturday", 1030, 590, 350],
        ["Sunday", 1030, 540, 450]
    ];

    const totalRevenueOptions = {
        chart: {
            // title: "Company Performance",
            // subtitle: "Total Revenue"
        },
        colors: ['#275AFF', '#1FB3FF', '#1CD3FF']
    };

    return (
        <>
            <div>
                <p>Total Revenue </p>
                $6,920,590
            </div>
            <div>
                <Chart
                    chartType="Bar"
                    width="80%"
                    height="300px"
                    data={totalRevenueData}
                    options={totalRevenueOptions}
                />
            </div>
            <div>
                <p>Total Order </p>
                $8,426,590
            </div>
            <div>
                <Chart
                    chartType="AreaChart"
                    width="100%"
                    height="400px"
                    data={totalOrderData}
                    options={totalOrderOptions}
                />
            </div>

        </>
    )
}

export default Sales;