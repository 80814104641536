import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { loadingImg, Searchlight, uploadImgs, userImg } from '../../../utilities/images'
import CustomModal from '../../components/shared/CustomModal';
import PaidAmountModal from './PaidAmountModal';

const TotalAmount = (props) => {

    const [activeDiscount , setActiveDiscount] = useState('20');
    const [activeAmount , setactiveAmount] = useState('310');
        const [key, setKey] = useState(Math.random());
        const [modalDetail, setModalDetail] = useState({
            show: false,
            title: "",
            flag: ""
        });
        const handleOnCloseModal = () => {
            setModalDetail({
                show: false,
                title: "",
                flag: ""
            });
            setKey(Math.random());
        }
    return (
    <div className='totalAmount'>
            <div className='row'>
                <div className='col-md-12 text-start'>
                    <h2 className='subHeading fw-bold'>Tips</h2>
                </div>
                <div className='col-md-4'>
                    <Link  href='#'
                    className={activeDiscount == "18" ? "amountField active " : 'amountField'} 
                    onClick={() => setActiveDiscount("18")}
                    > 18% </Link>
                </div>
                <div className='col-md-4'>
                    <Link  href='#'
                    className={activeDiscount == "20" ? "amountField active" : 'amountField'}
                    onClick={() => setActiveDiscount("20")}
                    > 20%</Link>
                </div>
                <div className='col-md-4'>
                    <Link  href='#'
                    className={activeDiscount == "22" ? "amountField active" : 'amountField'}
                    onClick={() => setActiveDiscount("22")}
                    > 22%</Link>
                </div>
                <div className='col-md-12 mt-4 mb-4'>
                    <Link  href='#' 
                    className={activeDiscount == "notips" ? "amountField active labelText_  m-0" : 'amountField labelText_  m-0'}
                    onClick={() => setActiveDiscount("notips")}
                    > No Tips</Link>
                </div>
                <div className='col-md-12 mt-5 mb-2 text-start'>
                <h2 className='subHeading fw-bold'>Cash amount received</h2>
                </div>
                <div className='col-md-4 mt-1 mb-4'>
                    <Link  href='#'
                    className={activeAmount == "310" ? "amountField active m-0" : 'amountField  m-0'}
                    onClick={() => setactiveAmount("310")}
                    > $310.00</Link>
                </div>
                <div className='col-md-4 mt-1 mb-4'>
                    <Link  href='#' 
                    className={activeAmount == "320" ? "amountField active m-0" : 'amountField  m-0'}
                    onClick={() => setactiveAmount("320")}
                    > $320.00</Link>
                </div>
                <div className='col-md-4 mt-1 mb-4'>
                    <Link  href='#'
                    className={activeAmount == "350" ? "amountField active m-0" : 'amountField  m-0'}
                    onClick={() => setactiveAmount("350")}
                    > $350.00</Link>
                </div>
                <div className='col-md-12 mt-1 mb-4'>
                    <input type='' className='form-control' placeholder='Other amount' value='' />
                </div>
            </div>
            <p className='redirectingText active' style={{cursor: 'pointer'}}
             onClick={() => {
                setModalDetail({ show: true, flag: 'paidAmountModal', });
                setKey(Math.random());
            }}
            >Rederecting 
            <img src={loadingImg} alt='loadingImg'  className='ms-3'/></p>
            <CustomModal
                key={key}
                show={modalDetail.show}
                backdrop="static"
                showCloseBtn={false}
                isRightSideModal={false}
                mediumWidth={false}
                checkoutModal={true}
                footerClasses="footerBtns"
                header={
                    <div className='modalHeader_'>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="common">
                                    <p className="checkout_heading">
                                        {modalDetail.flag === 'updatePriceModal' ?
                                            <>
                                                <span>Update Price</span>
                                            </>
                                            :
                                            (modalDetail.flag === "walletModal"
                                            ?
                                            <span>Customer & Payment</span>
                                            :
                                            (modalDetail.flag === "cashModal"
                                            ?
                                            <span>Customer</span>
                                            :
                                            (modalDetail.flag === "totalAmountModal"
                                            ?
                                            <span>Total amount: $254.60</span>
                                            :
                                            (modalDetail.flag === "paidAmountModal"
                                            ?
                                            <span>Paid: $255.00</span>
                                            :
                                            <>
                                                <span>Amount: </span>
                                                <span><sup> $</sup>382.75</span>
                                            </>
                                            ))))}
                                    </p>
                                    <span className="crossIcon" onClick={() => handleOnCloseModal()}>
                                        <i class="las la-times" aria-hidden="true"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                footer={
                    <div className='row'>
                        <button class="primary_btn mt-4 col-md-8" type="button">Continue    
                        <i class="las la-arrow-right ms-2 rightArrow"></i>
                        </button>
                    </div>
                }
                child={
                     modalDetail.flag ===  "paidAmountModal" ? <PaidAmountModal />
                     :""
                }
                onCloseModal={() => handleOnCloseModal()}
            />
    </div>
  )
}

export default TotalAmount