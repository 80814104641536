import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { checkModal, Robusto, searchProductImg } from '../../../utilities/images'

const SingleProductModal = (props) => {

    return (
      <div className='singleProductModal text-start'>
            <div className='row'>
                <div className='col-md-12'>
                    <button className='backBtn_ text-start' onClick={props.close}>
                    <i class="las la-arrow-left me-2 leftArrow"></i>
                    Back
                    </button>
                </div>
                <div className='col-md-12 mt-4'>
                   <h2 className='heading_'>Marlboro Red-Pack</h2>
                </div>
            </div>
      </div>
    )
}

export default SingleProductModal

