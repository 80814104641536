import React from 'react'
import { Link } from 'react-router-dom'
import { checkModal } from '../../../utilities/images'

const CheckoutModal = () => {
    return (
        <div className="checkout_modal">
            <div className="item_main">
                <div className="item_detail">
                    <img src={checkModal} className="img-fluid item_detailsImg" alt="arrow" />
                    <span className="">Marlboro Flavor Plus</span>
                </div>
                <div className="selected_item">
                    <select>
                        <option selected="">Pack</option>
                        <option>Box</option>
                        <option>Box</option>
                        <option>Box</option>
                        <option>Box</option>
                    </select>
                </div>
            </div>
            <div className="checkout_price">
                <p className="total_price">
                    <span>Price</span>
                    <span><sup>$</sup>382.75</span>
                </p>
            </div>
            <div className="marlborobox_items">
                <div className="add_cart">
                    <button className="digitBtn"><i className="las la-minus"
                        onclick="incrDecrone(0)"></i></button>
                    <span className="numberValue" id="noOfvalueone">0</span>
                    <button className="digitBtn"><i className="las la-plus"
                        onclick="incrDecrone(1)"></i>
                    </button>
                </div>
            </div>
            <div className="modal-footer footerBtns">
                <Link to="#" className="remove_cart btn__">Remove from cart</Link>
                <Link to="#" className="update_cart btn__">Update to cart</Link>
            </div>
        </div>
    )
}

export default CheckoutModal