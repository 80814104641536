import React from "react";
import { Link } from 'react-router-dom'
import NavbarCommonBack from "../dashboard/NavbarcommonBack";
import {
  locdelivery,
  money,
  watch,
  rightarrow,
  userimg,
  exdelivery,
} from "../../../utilities/images";

const orderdelivery = () => {
  
  return (
    <div className="container-fluid">
      <NavbarCommonBack />
      <main>
        <div className="MainContentpage">
          <div className="row">
            <div className="col-md-6">
              <div className="orderReview">
                <div className="ReviewHead">
                  <h3>Orders to review</h3>
                </div>
                <div className="table-responsive">
                  <table className="tabel-order">
                    <tr>
                      <div className="reviewContent active">
                        <td>
                          <div className="viewUser">
                            <h5>Rebecca R. Russell</h5>
                            <p className="distanceMiles">
                              <img src={locdelivery} />
                              <span>2.5 miles</span>
                            </p>
                          </div>
                        </td>
                        <td>
                          <div className="viewUser">
                            <h5>3 items</h5>
                            <p className="distanceMiles">
                              <img src={money} />
                              <span>$489.50</span>
                            </p>
                          </div>
                        </td>
                        <td>
                          <div className="viewUser">
                            <h5 className="bluehead">Expess delivery</h5>
                            <p className="distanceMiles">
                              <img src={watch} />
                              <span>Expess delivery</span>
                            </p>
                          </div>
                        </td>
                        <td>
                          <div className="viewUser">
                            <img src={rightarrow} />
                          </div>
                        </td>
                      </div>
                    </tr>
                    <tr>
                      <div className="reviewContent">
                        <td>
                          <div className="viewUser">
                            <h5>Rebecca R. Russell</h5>
                            <p className="distanceMiles">
                              <img src={locdelivery} />
                              <span>2.5 miles</span>
                            </p>
                          </div>
                        </td>
                        <td>
                          <div className="viewUser">
                            <h5>3 items</h5>
                            <p className="distanceMiles">
                              <img src={money} />
                              <span>$489.50</span>
                            </p>
                          </div>
                        </td>
                        <td>
                          <div className="viewUser">
                            <h5 className="bluehead">Expess delivery</h5>
                            <p className="distanceMiles">
                              <img src={watch} />
                              <span>Expess delivery</span>
                            </p>
                          </div>
                        </td>
                        <td>
                          <div className="viewUser">
                            <img src={rightarrow} />
                          </div>
                        </td>
                      </div>
                    </tr>
                    <tr>
                      <div className="reviewContent">
                        <td>
                          <div className="viewUser">
                            <h5>Rebecca R. Russell</h5>
                            <p className="distanceMiles">
                              <img src={locdelivery} />
                              <span>2.5 miles</span>
                            </p>
                          </div>
                        </td>
                        <td>
                          <div className="viewUser">
                            <h5>3 items</h5>
                            <p className="distanceMiles">
                              <img src={money} />
                              <span>$489.50</span>
                            </p>
                          </div>
                        </td>
                        <td>
                          <div className="viewUser">
                            <h5 className="bluehead">Expess delivery</h5>
                            <p className="distanceMiles">
                              <img src={watch} />
                              <span>Expess delivery</span>
                            </p>
                          </div>
                        </td>
                        <td>
                          <div className="viewUser">
                            <img src={rightarrow} />
                          </div>
                        </td>
                      </div>
                    </tr>
                    <tr>
                      <div className="reviewContent">
                        <td>
                          <div className="viewUser">
                            <h5>Rebecca R. Russell</h5>
                            <p className="distanceMiles">
                              <img src={locdelivery} />
                              <span>2.5 miles</span>
                            </p>
                          </div>
                        </td>
                        <td>
                          <div className="viewUser">
                            <h5>3 items</h5>
                            <p className="distanceMiles">
                              <img src={money} />
                              <span>$489.50</span>
                            </p>
                          </div>
                        </td>
                        <td>
                          <div className="viewUser">
                            <h5 className="bluehead">Expess delivery</h5>
                            <p className="distanceMiles">
                              <img src={watch} />
                              <span>Expess delivery</span>
                            </p>
                          </div>
                        </td>
                        <td>
                          <div className="viewUser">
                            <img src={rightarrow} />
                          </div>
                        </td>
                      </div>
                    </tr>
                    <tr>
                      <div className="reviewContent">
                        <td>
                          <div className="viewUser">
                            <h5>Rebecca R. Russell</h5>
                            <p className="distanceMiles">
                              <img src={locdelivery} />
                              <span>2.5 miles</span>
                            </p>
                          </div>
                        </td>
                        <td>
                          <div className="viewUser">
                            <h5>3 items</h5>
                            <p className="distanceMiles">
                              <img src={money} />
                              <span>$489.50</span>
                            </p>
                          </div>
                        </td>
                        <td>
                          <div className="viewUser">
                            <h5 className="bluehead">Expess delivery</h5>
                            <p className="distanceMiles">
                              <img src={watch} />
                              <span>Expess delivery</span>
                            </p>
                          </div>
                        </td>
                        <td>
                          <div className="viewUser">
                            <img src={rightarrow} />
                          </div>
                        </td>
                      </div>
                    </tr>
                    <tr>
                      <div className="reviewContent">
                        <td>
                          <div className="viewUser">
                            <h5>Rebecca R. Russell</h5>
                            <p className="distanceMiles">
                              <img src={locdelivery} />
                              <span>2.5 miles</span>
                            </p>
                          </div>
                        </td>
                        <td>
                          <div className="viewUser">
                            <h5>3 items</h5>
                            <p className="distanceMiles">
                              <img src={money} />
                              <span>$489.50</span>
                            </p>
                          </div>
                        </td>
                        <td>
                          <div className="viewUser">
                            <h5 className="bluehead">Expess delivery</h5>
                            <p className="distanceMiles">
                              <img src={watch} />
                              <span>Expess delivery</span>
                            </p>
                          </div>
                        </td>
                        <td>
                          <div className="viewUser">
                            <img src={rightarrow} />
                          </div>
                        </td>
                      </div>
                    </tr>
                    <tr>
                      <div className="reviewContent">
                        <td>
                          <div className="viewUser">
                            <h5>Rebecca R. Russell</h5>
                            <p className="distanceMiles">
                              <img src={locdelivery} />
                              <span>2.5 miles</span>
                            </p>
                          </div>
                        </td>
                        <td>
                          <div className="viewUser">
                            <h5>3 items</h5>
                            <p className="distanceMiles">
                              <img src={money} />
                              <span>$489.50</span>
                            </p>
                          </div>
                        </td>
                        <td>
                          <div className="viewUser">
                            <h5 className="bluehead">Expess delivery</h5>
                            <p className="distanceMiles">
                              <img src={watch} />
                              <span>Expess delivery</span>
                            </p>
                          </div>
                        </td>
                        <td>
                          <div className="viewUser">
                            <img src={rightarrow} />
                          </div>
                        </td>
                      </div>
                    </tr>
                    <tr>
                      <div className="reviewContent">
                        <td>
                          <div className="viewUser">
                            <h5>Rebecca R. Russell</h5>
                            <p className="distanceMiles">
                              <img src={locdelivery} />
                              <span>2.5 miles</span>
                            </p>
                          </div>
                        </td>
                        <td>
                          <div className="viewUser">
                            <h5>3 items</h5>
                            <p className="distanceMiles">
                              <img src={money} />
                              <span>$489.50</span>
                            </p>
                          </div>
                        </td>
                        <td>
                          <div className="viewUser">
                            <h5 className="bluehead">Expess delivery</h5>
                            <p className="distanceMiles">
                              <img src={watch} />
                              <span>Expess delivery</span>
                            </p>
                          </div>
                        </td>
                        <td>
                          <div className="viewUser">
                            <img src={rightarrow} />
                          </div>
                        </td>
                      </div>
                    </tr>
                    <tr>
                      <div className="reviewContent">
                        <td>
                          <div className="viewUser">
                            <h5>Rebecca R. Russell</h5>
                            <p className="distanceMiles">
                              <img src={locdelivery} />
                              <span>2.5 miles</span>
                            </p>
                          </div>
                        </td>
                        <td>
                          <div className="viewUser">
                            <h5>3 items</h5>
                            <p className="distanceMiles">
                              <img src={money} />
                              <span>$489.50</span>
                            </p>
                          </div>
                        </td>
                        <td>
                          <div className="viewUser">
                            <h5 className="bluehead">Expess delivery</h5>
                            <p className="distanceMiles">
                              <img src={watch} />
                              <span>Expess delivery</span>
                            </p>
                          </div>
                        </td>
                        <td>
                          <div className="viewUser">
                            <img src={rightarrow} />
                          </div>
                        </td>
                      </div>
                    </tr>
                    <tr>
                      <div className="reviewContent">
                        <td>
                          <div className="viewUser">
                            <h5>Rebecca R. Russell</h5>
                            <p className="distanceMiles">
                              <img src={locdelivery} />
                              <span>2.5 miles</span>
                            </p>
                          </div>
                        </td>
                        <td>
                          <div className="viewUser">
                            <h5>3 items</h5>
                            <p className="distanceMiles">
                              <img src={money} />
                              <span>$489.50</span>
                            </p>
                          </div>
                        </td>
                        <td>
                          <div className="viewUser">
                            <h5 className="bluehead">Expess delivery</h5>
                            <p className="distanceMiles">
                              <img src={watch} />
                              <span>Expess delivery</span>
                            </p>
                          </div>
                        </td>
                        <td>
                          <div className="viewUser">
                            <img src={rightarrow} />
                          </div>
                        </td>
                      </div>
                    </tr>
                    <tr>
                      <div className="reviewContent">
                        <td>
                          <div className="viewUser">
                            <h5>Rebecca R. Russell</h5>
                            <p className="distanceMiles">
                              <img src={locdelivery} />
                              <span>2.5 miles</span>
                            </p>
                          </div>
                        </td>
                        <td>
                          <div className="viewUser">
                            <h5>3 items</h5>
                            <p className="distanceMiles">
                              <img src={money} />
                              <span>$489.50</span>
                            </p>
                          </div>
                        </td>
                        <td>
                          <div className="viewUser">
                            <h5 className="bluehead">Expess delivery</h5>
                            <p className="distanceMiles">
                              <img src={watch} />
                              <span>Expess delivery</span>
                            </p>
                          </div>
                        </td>
                        <td>
                          <div className="viewUser">
                            <img src={rightarrow} />
                          </div>
                        </td>
                      </div>
                    </tr>
                    <tr>
                      <div className="reviewContent">
                        <td>
                          <div className="viewUser">
                            <h5>Rebecca R. Russell</h5>
                            <p className="distanceMiles">
                              <img src={locdelivery} />
                              <span>2.5 miles</span>
                            </p>
                          </div>
                        </td>
                        <td>
                          <div className="viewUser">
                            <h5>3 items</h5>
                            <p className="distanceMiles">
                              <img src={money} />
                              <span>$489.50</span>
                            </p>
                          </div>
                        </td>
                        <td>
                          <div className="viewUser">
                            <h5 className="bluehead">Expess delivery</h5>
                            <p className="distanceMiles">
                              <img src={watch} />
                              <span>Expess delivery</span>
                            </p>
                          </div>
                        </td>
                        <td>
                          <div className="viewUser">
                            <img src={rightarrow} />
                          </div>
                        </td>
                      </div>
                    </tr>
                    <tr>
                      <div className="reviewContent">
                        <td>
                          <div className="viewUser">
                            <h5>Rebecca R. Russell</h5>
                            <p className="distanceMiles">
                              <img src={locdelivery} />
                              <span>2.5 miles</span>
                            </p>
                          </div>
                        </td>
                        <td>
                          <div className="viewUser">
                            <h5>3 items</h5>
                            <p className="distanceMiles">
                              <img src={money} />
                              <span>$489.50</span>
                            </p>
                          </div>
                        </td>
                        <td>
                          <div className="viewUser">
                            <h5 className="bluehead">Expess delivery</h5>
                            <p className="distanceMiles">
                              <img src={watch} />
                              <span>Expess delivery</span>
                            </p>
                          </div>
                        </td>
                        <td>
                          <div className="viewUser">
                            <img src={rightarrow} />
                          </div>
                        </td>
                      </div>
                    </tr>
                    <tr>
                      <div className="reviewContent">
                        <td>
                          <div className="viewUser">
                            <h5>Rebecca R. Russell</h5>
                            <p className="distanceMiles">
                              <img src={locdelivery} />
                              <span>2.5 miles</span>
                            </p>
                          </div>
                        </td>
                        <td>
                          <div className="viewUser">
                            <h5>3 items</h5>
                            <p className="distanceMiles">
                              <img src={money} />
                              <span>$489.50</span>
                            </p>
                          </div>
                        </td>
                        <td>
                          <div className="viewUser">
                            <h5 className="bluehead">Expess delivery</h5>
                            <p className="distanceMiles">
                              <img src={watch} />
                              <span>Expess delivery</span>
                            </p>
                          </div>
                        </td>
                        <td>
                          <div className="viewUser">
                            <img src={rightarrow} />
                          </div>
                        </td>
                      </div>
                    </tr>
                    <tr>
                      <div className="reviewContent">
                        <td>
                          <div className="viewUser">
                            <h5>Rebecca R. Russell</h5>
                            <p className="distanceMiles">
                              <img src={locdelivery} />
                              <span>2.5 miles</span>
                            </p>
                          </div>
                        </td>
                        <td>
                          <div className="viewUser">
                            <h5>3 items</h5>
                            <p className="distanceMiles">
                              <img src={money} />
                              <span>$489.50</span>
                            </p>
                          </div>
                        </td>
                        <td>
                          <div className="viewUser">
                            <h5 className="bluehead">Expess delivery</h5>
                            <p className="distanceMiles">
                              <img src={watch} />
                              <span>Expess delivery</span>
                            </p>
                          </div>
                        </td>
                        <td>
                          <div className="viewUser">
                            <img src={rightarrow} />
                          </div>
                        </td>
                      </div>
                    </tr>
                    <tr>
                      <div className="reviewContent">
                        <td>
                          <div className="viewUser">
                            <h5>Rebecca R. Russell</h5>
                            <p className="distanceMiles">
                              <img src={locdelivery} />
                              <span>2.5 miles</span>
                            </p>
                          </div>
                        </td>
                        <td>
                          <div className="viewUser">
                            <h5>3 items</h5>
                            <p className="distanceMiles">
                              <img src={money} />
                              <span>$489.50</span>
                            </p>
                          </div>
                        </td>
                        <td>
                          <div className="viewUser">
                            <h5 className="bluehead">Expess delivery</h5>
                            <p className="distanceMiles">
                              <img src={watch} />
                              <span>Expess delivery</span>
                            </p>
                          </div>
                        </td>
                        <td>
                          <div className="viewUser">
                            <img src={rightarrow} />
                          </div>
                        </td>
                      </div>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
            <div className="col-md-6 ">
              <div className="orderExpress">
                <div className="ReviewHead">
                  <h3>Order#WXCOO1256</h3>
                  <span>August 30, 2022</span>
                </div>
                <div className="orderDetails_">
                  <div className="userDetail">
                    <img src={userimg} />
                    <div className="userContent">
                      <h3>Rebecca R. Russell</h3>
                      <h6>1480 Bassel Street, New Orleans, LA 70113</h6>
                    </div>
                  </div>
                  <div className="userDetail">
                    <img src={exdelivery} />
                    <div className="userContent">
                      <h3 class="blueText_">Express Delivery</h3>
                      <h6>Immediately</h6>
                    </div>
                  </div>
                </div>
                <div className="listOrders">
                  <table className="table table-responsive listItemsContainer">
                    <tbody>
                      <tr role="row">
                        <td className="col-md-4">
                          <div class="sidebarbody_icon">
                            <img
                              src="/static/media/Robusto.3038483791f90b500f3c.jpg"
                              alt="arrow"
                            />
                            <div class="productsDetails">
                              <h6>JFR Maduro</h6>
                              <p class="box_">Box</p>
                            </div>
                          </div>
                        </td>
                        <td className="col-md-4 text-center">
                          <p class="productlimit">
                            <small>x</small>
                            <b>1</b>
                          </p>
                        </td>
                        <td className="col-md-4 text-end">
                          <p className="price_amount">
                            <sup>$</sup>382.75
                          </p>
                        </td>
                      </tr>
                      <tr role="row">
                        <td className="col-md-4">
                          <div class="sidebarbody_icon">
                            <img
                              src="/static/media/Robusto.3038483791f90b500f3c.jpg"
                              alt="arrow"
                            />
                            <div class="productsDetails">
                              <h6>Ashton Magnum</h6>
                              <p class="box_">Box</p>
                            </div>
                          </div>
                        </td>
                        <td className="col-md-4 text-center">
                          <p class="productlimit">
                            <small>x</small>
                            <b>1</b>
                          </p>
                        </td>
                        <td className="col-md-4 text-end">
                          <p className="price_amount">
                            <sup>$</sup>236.50
                          </p>
                        </td>
                      </tr>
                      <tr role="row">
                        <td className="col-md-4">
                          <div class="sidebarbody_icon">
                            <img
                              src="/static/media/Robusto.3038483791f90b500f3c.jpg"
                              alt="arrow"
                            />
                            <div class="productsDetails">
                              <h6>Marlboro Touch</h6>
                              <p class="box_">Box</p>
                            </div>
                          </div>
                        </td>
                        <td className="col-md-4 text-center">
                          <p class="productlimit">
                            <small>x</small>
                            <b>1</b>
                          </p>
                        </td>
                        <td className="col-md-4 text-end">
                          <p className="price_amount">
                            <sup>$</sup>43.99
                          </p>
                        </td>
                      </tr>
                      <tr role="row">
                        <td className="col-md-4">
                          <div class="sidebarbody_icon">
                            <img
                              src="/static/media/Robusto.3038483791f90b500f3c.jpg"
                              alt="arrow"
                            />
                            <div class="productsDetails">
                              <h6>Hokkah</h6>
                              <p class="box_">Box</p>
                            </div>
                          </div>
                        </td>
                        <td className="col-md-4 text-center">
                          <p class="productlimit">
                            <small>x</small>
                            <b>1</b>
                          </p>
                        </td>
                        <td className="col-md-4 text-end">
                          <p className="price_amount">
                            <sup>$</sup>5.75
                          </p>
                        </td>
                      </tr>
                      <tr role="row">
                        <td className="col-md-4">
                          <div class="sidebarbody_icon">
                            <img
                              src="/static/media/Robusto.3038483791f90b500f3c.jpg"
                              alt="arrow"
                            />
                            <div class="productsDetails">
                              <h6>Hokkah</h6>
                              <p class="box_">Box</p>
                            </div>
                          </div>
                        </td>
                        <td className="col-md-4 text-center">
                          <p class="productlimit">
                            <small>x</small>
                            <b>1</b>
                          </p>
                        </td>
                        <td className="col-md-4 text-end">
                          <p className="price_amount">
                            <sup>$</sup>5.75
                          </p>
                        </td>
                      </tr>
                      <tr role="row">
                        <td className="col-md-4">
                          <div class="sidebarbody_icon">
                            <img
                              src="/static/media/Robusto.3038483791f90b500f3c.jpg"
                              alt="arrow"
                            />
                            <div class="productsDetails">
                              <h6>Hokkah</h6>
                              <p class="box_">Box</p>
                            </div>
                          </div>
                        </td>
                        <td className="col-md-4 text-center">
                          <p class="productlimit">
                            <small>x</small>
                            <b>1</b>
                          </p>
                        </td>
                        <td className="col-md-4 text-end">
                          <p className="price_amount">
                            <sup>$</sup>5.75
                          </p>
                        </td>
                      </tr>
                      <tr role="row">
                        <td className="col-md-4">
                          <div class="sidebarbody_icon">
                            <img
                              src="/static/media/Robusto.3038483791f90b500f3c.jpg"
                              alt="arrow"
                            />
                            <div class="productsDetails">
                              <h6>Hokkah</h6>
                              <p class="box_">Box</p>
                            </div>
                          </div>
                        </td>
                        <td className="col-md-4 text-center">
                          <p class="productlimit">
                            <small>x</small>
                            <b>1</b>
                          </p>
                        </td>
                        <td className="col-md-4 text-end">
                          <p className="price_amount">
                            <sup>$</sup>5.75
                          </p>
                        </td>
                      </tr>
                      <tr role="row">
                        <td className="col-md-4">
                          <div class="sidebarbody_icon">
                            <img
                              src="/static/media/Robusto.3038483791f90b500f3c.jpg"
                              alt="arrow"
                            />
                            <div class="productsDetails">
                              <h6>Hokkah</h6>
                              <p class="box_">Box</p>
                            </div>
                          </div>
                        </td>
                        <td className="col-md-4 text-center">
                          <p class="productlimit">
                            <small>x</small>
                            <b>1</b>
                          </p>
                        </td>
                        <td className="col-md-4 text-end">
                          <p className="price_amount">
                            <sup>$</sup>5.75
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="expressOrderFooter sidebarFooter">
                  <div className="row justify-content-end">
                    <div className="priceBox">
                      <p class="total_price">
                        <strong>Sub Total</strong>
                        <small class="total_amount">
                          <sup>$</sup>4.00
                        </small>
                      </p>
                      <p class="total_price">
                        <small>Sub Total</small>
                        <small class="total_amount">
                          <sup>-$</sup>2.00
                        </small>
                      </p>
                      <p class="total_price">
                        <small>Sub Total</small>
                        <small class="total_amount">
                          <sup>$</sup>4.00
                        </small>
                      </p>
                      <p class="total_price">
                        <strong class="total_amount">Total</strong>
                        <strong class="total_amount">
                          <sup>$</sup>254.60
                        </strong>
                      </p>
                      <p class="total_price">
                        <span class="total_amount">
                          4<small>Items</small>
                        </span>
                      </p>
                      <div className="expressbtn">
                        <Link to="#" class=" declinebtn mt-4 mb-4" type="button">
                          Decline{" "}
                        </Link>
                        <Link to="/Orderprepare" class="acceptbtn mt-4 mb-4" type="button">
                          Accept Order{" "}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default orderdelivery;
