import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { loadingImg, uploadImgs, userImg } from '../../../utilities/images'

const PaymentDetails = () => {
    const [activeClass, setActivewidth] = useState(false);
    const openActions = (id) => {
        document.getElementById(id).setAttribute("style","width:32%");
        document.getElementById("scrollHide").setAttribute("style", "overflow:hidden")
        // document.getElementById("paymentDetails").setAttribute("style", "overflow:hidden")
        setActivewidth(true)
    }
    const closeActions = (id) => {
        document.getElementById(id).setAttribute("style", "width:0");
        setActivewidth(false)
    }
  return (
    <div className={activeClass ?'paymentDetails topSpace activewidth':'paymentDetails topSpace'}>
        <div className='container-fluid'>
            <div className='row align-items-center mb-3'>
                <div className='col-md-6'>
                    <h2 className='listHeading'>
                        List of items <span className='marlboro_subheading fw-normal'>4 items</span>
                    </h2>
                </div>
                <div className='col-md-6 text-end'>
                    <Link href='#' className='rewardTxt'>Reward Points 5.00</Link>
                </div>
            </div>
            <table className='table table-responsive listItemsContainer'>
                <tbody>
                    <tr role='row' >
                        <td className='col-md-4'>
                            <div class="sidebarbody_icon"
                            onClick={() => openActions('paymentSideBar')}
                            >
                                <img src="/static/media/Robusto.3038483791f90b500f3c.jpg" alt="arrow" />
                                <div class="productsDetails">
                                    <h6>JFR Maduro</h6>
                                    <p class="box_">Box</p>
                                </div>
                            </div>
                        </td>
                        <td className='col-md-4 text-center'>
                            <p class="productlimit">
                                <small>x</small>
                                <b>1</b>
                            </p>
                                    
                        </td>
                        <td className='col-md-4 text-end'>
                            <p className='price_amount'>
                                <sup>$</sup>382.75
                            </p>
                        </td>
                    </tr>
                    <tr role='row'>
                        <td className='col-md-4'>
                            <div class="sidebarbody_icon">
                                <img src="/static/media/Robusto.3038483791f90b500f3c.jpg" alt="arrow" />
                                <div class="productsDetails">
                                    <h6>Ashton Magnum</h6>
                                    <p class="box_">Box</p>
                                </div>
                            </div>
                        </td>
                        <td className='col-md-4 text-center'>
                            <p class="productlimit">
                                <small>x</small>
                                <b>1</b>
                            </p>
                                    
                        </td>
                        <td className='col-md-4 text-end'>
                            <p className='price_amount'>
                                <sup>$</sup>236.50
                            </p>
                        </td>
                    </tr>
                    <tr role='row'>
                        <td className='col-md-4'>
                            <div class="sidebarbody_icon">
                                <img src="/static/media/Robusto.3038483791f90b500f3c.jpg" alt="arrow" />
                                <div class="productsDetails">
                                    <h6>Marlboro Touch</h6>
                                    <p class="box_">Box</p>
                                </div>
                            </div>
                        </td>
                        <td className='col-md-4 text-center'>
                            <p class="productlimit">
                                <small>x</small>
                                <b>1</b>
                            </p>
                                    
                        </td>
                        <td className='col-md-4 text-end'>
                            <p className='price_amount'>
                                <sup>$</sup>43.99
                            </p>
                        </td>
                    </tr>
                    <tr role='row'>
                        <td className='col-md-4'>
                            <div class="sidebarbody_icon">
                                <img src="/static/media/Robusto.3038483791f90b500f3c.jpg" alt="arrow" />
                                <div class="productsDetails">
                                    <h6>Hokkah</h6>
                                    <p class="box_">Box</p>
                                </div>
                            </div>
                        </td>
                        <td className='col-md-4 text-center'>
                            <p class="productlimit">
                                <small>x</small>
                                <b>1</b>
                            </p>
                                    
                        </td>
                        <td className='col-md-4 text-end'>
                            <p className='price_amount'>
                                <sup>$</sup>5.75
                            </p>
                        </td>
                    </tr>
                </tbody>
            </table>    
            <div id='paymentSideBar' className='moreAction'>
                    <div className='moreActionInner'>
                        <header className='actionHeader'>
                            <h6>Payment Details</h6>
                            <button className='closeAction' onClick={() => closeActions('paymentSideBar')}>
                                <i class="las la-times"></i>
                            </button>
                        </header>
                        <div className='paymentDoneArea mb-3'>
                            <h2 className='text-center listHeading fw-normal'>Payment Done!</h2>
                            <div className='paymentDoneContainer'>
                                <div className='paymentDoneText'>
                                    <div>
                                        <h3 className='payableTxt'>
                                            <span>Payable</span>
                                            <span className='payableAmount ms-2'>$254.60</span>
                                        </h3>
                                        <h3 className='payableTxt'>
                                            <span>Tips</span>
                                            <span className='payableAmount ms-2'>$51.00</span>
                                        </h3>   
                                    </div>
                                    <h5 className='paymentAmount'> $306.60</h5>
                                </div>
                            </div>
                            <Link href='#' className='linkTxt text-center d-block mt-3'><small className='contentSubtext_'><i>Via </i></small>JBR Wallet</Link>
                        </div>
                        <div className='customerContainer'>
                            <h3>Customer</h3>
                            <div class="customerDetailsArea pt-2 pb-4">
                                <img src={userImg} alt="userName" class="userImg" />
                                <div class="customerDetails">
                                    <h2 class="subHeading">Terry Moore</h2>
                                    <p class="innerSubtext fw-normal">803-238-2630</p>
                                    <Link href='mailto:someone@yoursite.com' className='innerSubtext fw-normal mt-2 mb-2 d-inline-block'>harryrady@jourrapide.com</Link>
                                    <aside>
                                        4849 Owagner Lane
                                        <br />Seattle, WA 98101
                                    </aside>
                                </div>
                            </div>
                            <div className='walletId'>
                                <h6 className='contentSubtext_ m-2'>Wallet Id</h6>
                                <p className='linkTxt'>509 236 2365</p>
                            </div>
                        </div>
                        <div class="sidebarFooter">
                            <p class="total_price">
                                <strong>Sub Total</strong>
                                <small class="total_amount"><sup>$</sup>4.00</small>
                            </p>
                            <p class="total_price"><small>Sub Total</small>
                                <small class="total_amount"><sup>-$</sup>2.00</small>
                            </p>
                            <p class="total_price">
                                <small>Sub Total</small>
                                <small class="total_amount"><sup>$</sup>4.00</small>
                            </p>
                            <p class="total_price">
                                <strong class="total_amount">Total</strong>
                                <strong class="total_amount"><sup>$</sup>254.60</strong>
                            </p>
                            <p class="total_price">
                                <span class="total_amount">4<small>Items</small></span>
                            </p>
                            
                            <button class="primary_btn mt-4 mb-4" type="button">Checkout <i class="las la-arrow-right ms-2 rightArrow"></i></button>
                        </div>
                    </div>
            </div>    
        </div>
    </div>
  )
}

export default PaymentDetails