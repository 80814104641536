import React from 'react'
import DashboardNavbar from './DashboardNavbar'
import DashboardProduct from './DashboardProduct'
// import { bagImg } from '../../../utilities/images'

const Dashboard = () => {
  return (
      <div className="container-fluid">
        <DashboardNavbar />
        <DashboardProduct/>
      </div>
  )
}

export default Dashboard