import { Switch, Route } from 'react-router-dom';
import * as Layouts from './../app/layouts';
import * as Containers from './../app/containers';

function Router() {

  return (
    <Switch>
      {/* Auth Routes*/}

      {/* login forget password routes */}
      <Layouts.NonPrivateRoute exact path="/" component={Containers.Login} />
      <Layouts.NonPrivateRoute exact path="/verify-phone" component={Containers.VerifyPhone} />
      <Layouts.NonPrivateRoute exact path="/set-passcode" component={Containers.SetPasscode} />
      <Layouts.PrivateRoute exact path="/dashboard" component={Containers.Dashboard} />
      <Layouts.PrivateRoute exact path="/delivery" component={Containers.Delivery} />
      <Layouts.PrivateRoute exact path="/shipping" component={Containers.shipping} />
      <Layouts.PrivateRoute exact path="/ShippingOrder" component={Containers.ShippingOrder} />
      <Layouts.PrivateRoute exact path="/Selectshipping" component={Containers.Selectshipping} />
      <Layouts.PrivateRoute exact path="/analytics" component={Containers.analytics} />
      <Layouts.PrivateRoute exact path="/orderDelivery" component={Containers.orderDelivery} />
      <Layouts.PrivateRoute exact path="/Orderprepare" component={Containers.Orderprepare} />
      <Layouts.PrivateRoute exact path="/ReadyPickup" component={Containers.ReadyPickup} />
      <Layouts.PrivateRoute exact path="/customers" component={Containers.customers} />
      <Layouts.PrivateRoute exact path="/wallet" component={Containers.wallet} />
      <Layouts.PrivateRoute exact path="/drawerCash" component={Containers.drawerCash} />
      <Layouts.PrivateRoute exact path="/calender" component={Containers.calender} />
      <Layouts.PrivateRoute exact path="/rewards" component={Containers.rewards} />
      <Layouts.PrivateRoute exact path="/settings" component={Containers.settings} />
      <Layouts.PrivateRoute exact path="/sales" component={Containers.Sales} />
      <Layouts.PrivateRoute exact path="/analytics" component={Containers.AnalticsDashboard} />
      <Layouts.PrivateRoute exact path="/payment-details" component={Containers.PaymentDetails} />
      <Route component={Containers.NotFound} />
    </Switch>
  );
}

export default Router;