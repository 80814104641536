import React, { useState } from "react";
import NavbarCommonBack from "../dashboard/NavbarcommonBack";
import {
  locdelivery,
  money,
  watch,
  rightarrow,
  userimg,
  exdelivery,
  unselected,
  cancleorder,
  pickuporder,
  verifyorder,
  storelogo,
} from "../../../utilities/images";

const ReadyPickup = () => {
  const [orderStatus , setorderStatus] = useState(true);
  const handleClick = event => {
    // 👇️ toggle isActive state on click
    setorderStatus(current => !current);
  };

  return (
    <div className="container-fluid">
      <NavbarCommonBack />
      <main>
        <div className="MainContentpage">
          <div className="row">
            <div className="col-md-6">
              <div className="orderReview">
                <div className="ReviewHead">
                  <h3>Orders to review</h3>
                </div>
                <div className="table-responsive">
                  <table className="tabel-order">
                    <tr>
                      <div className="reviewContent active">
                        <td>
                          <div className="viewUser">
                            <h5>Rebecca R. Russell</h5>
                            <p className="distanceMiles">
                              <img src={locdelivery} />
                              <span>2.5 miles</span>
                            </p>
                          </div>
                        </td>
                        <td>
                          <div className="viewUser">
                            <h5>3 items</h5>
                            <p className="distanceMiles">
                              <img src={money} />
                              <span>$489.50</span>
                            </p>
                          </div>
                        </td>
                        <td>
                          <div className="viewUser">
                            <h5 className="bluehead">Expess delivery</h5>
                            <p className="distanceMiles">
                              <img src={watch} />
                              <span>Expess delivery</span>
                            </p>
                          </div>
                        </td>
                        <td>
                          <div className="viewUser">
                            <img src={rightarrow} />
                          </div>
                        </td>
                      </div>
                    </tr>
                    <tr>
                      <div className="reviewContent">
                        <td>
                          <div className="viewUser">
                            <h5>Rebecca R. Russell</h5>
                            <p className="distanceMiles">
                              <img src={locdelivery} />
                              <span>2.5 miles</span>
                            </p>
                          </div>
                        </td>
                        <td>
                          <div className="viewUser">
                            <h5>3 items</h5>
                            <p className="distanceMiles">
                              <img src={money} />
                              <span>$489.50</span>
                            </p>
                          </div>
                        </td>
                        <td>
                          <div className="viewUser">
                            <h5 className="bluehead">Expess delivery</h5>
                            <p className="distanceMiles">
                              <img src={watch} />
                              <span>Expess delivery</span>
                            </p>
                          </div>
                        </td>
                        <td>
                          <div className="viewUser">
                            <img src={rightarrow} />
                          </div>
                        </td>
                      </div>
                    </tr>
                    <tr>
                      <div className="reviewContent">
                        <td>
                          <div className="viewUser">
                            <h5>Rebecca R. Russell</h5>
                            <p className="distanceMiles">
                              <img src={locdelivery} />
                              <span>2.5 miles</span>
                            </p>
                          </div>
                        </td>
                        <td>
                          <div className="viewUser">
                            <h5>3 items</h5>
                            <p className="distanceMiles">
                              <img src={money} />
                              <span>$489.50</span>
                            </p>
                          </div>
                        </td>
                        <td>
                          <div className="viewUser">
                            <h5 className="bluehead">Expess delivery</h5>
                            <p className="distanceMiles">
                              <img src={watch} />
                              <span>Expess delivery</span>
                            </p>
                          </div>
                        </td>
                        <td>
                          <div className="viewUser">
                            <img src={rightarrow} />
                          </div>
                        </td>
                      </div>
                    </tr>
                    <tr>
                      <div className="reviewContent">
                        <td>
                          <div className="viewUser">
                            <h5>Rebecca R. Russell</h5>
                            <p className="distanceMiles">
                              <img src={locdelivery} />
                              <span>2.5 miles</span>
                            </p>
                          </div>
                        </td>
                        <td>
                          <div className="viewUser">
                            <h5>3 items</h5>
                            <p className="distanceMiles">
                              <img src={money} />
                              <span>$489.50</span>
                            </p>
                          </div>
                        </td>
                        <td>
                          <div className="viewUser">
                            <h5 className="bluehead">Expess delivery</h5>
                            <p className="distanceMiles">
                              <img src={watch} />
                              <span>Expess delivery</span>
                            </p>
                          </div>
                        </td>
                        <td>
                          <div className="viewUser">
                            <img src={rightarrow} />
                          </div>
                        </td>
                      </div>
                    </tr>
                    <tr>
                      <div className="reviewContent">
                        <td>
                          <div className="viewUser">
                            <h5>Rebecca R. Russell</h5>
                            <p className="distanceMiles">
                              <img src={locdelivery} />
                              <span>2.5 miles</span>
                            </p>
                          </div>
                        </td>
                        <td>
                          <div className="viewUser">
                            <h5>3 items</h5>
                            <p className="distanceMiles">
                              <img src={money} />
                              <span>$489.50</span>
                            </p>
                          </div>
                        </td>
                        <td>
                          <div className="viewUser">
                            <h5 className="bluehead">Expess delivery</h5>
                            <p className="distanceMiles">
                              <img src={watch} />
                              <span>Expess delivery</span>
                            </p>
                          </div>
                        </td>
                        <td>
                          <div className="viewUser">
                            <img src={rightarrow} />
                          </div>
                        </td>
                      </div>
                    </tr>
                    <tr>
                      <div className="reviewContent">
                        <td>
                          <div className="viewUser">
                            <h5>Rebecca R. Russell</h5>
                            <p className="distanceMiles">
                              <img src={locdelivery} />
                              <span>2.5 miles</span>
                            </p>
                          </div>
                        </td>
                        <td>
                          <div className="viewUser">
                            <h5>3 items</h5>
                            <p className="distanceMiles">
                              <img src={money} />
                              <span>$489.50</span>
                            </p>
                          </div>
                        </td>
                        <td>
                          <div className="viewUser">
                            <h5 className="bluehead">Expess delivery</h5>
                            <p className="distanceMiles">
                              <img src={watch} />
                              <span>Expess delivery</span>
                            </p>
                          </div>
                        </td>
                        <td>
                          <div className="viewUser">
                            <img src={rightarrow} />
                          </div>
                        </td>
                      </div>
                    </tr>
                    <tr>
                      <div className="reviewContent">
                        <td>
                          <div className="viewUser">
                            <h5>Rebecca R. Russell</h5>
                            <p className="distanceMiles">
                              <img src={locdelivery} />
                              <span>2.5 miles</span>
                            </p>
                          </div>
                        </td>
                        <td>
                          <div className="viewUser">
                            <h5>3 items</h5>
                            <p className="distanceMiles">
                              <img src={money} />
                              <span>$489.50</span>
                            </p>
                          </div>
                        </td>
                        <td>
                          <div className="viewUser">
                            <h5 className="bluehead">Expess delivery</h5>
                            <p className="distanceMiles">
                              <img src={watch} />
                              <span>Expess delivery</span>
                            </p>
                          </div>
                        </td>
                        <td>
                          <div className="viewUser">
                            <img src={rightarrow} />
                          </div>
                        </td>
                      </div>
                      <div className="reviewContent">
                        <td>
                          <div className="viewUser">
                            <h5>Rebecca R. Russell</h5>
                            <p className="distanceMiles">
                              <img src={locdelivery} />
                              <span>2.5 miles</span>
                            </p>
                          </div>
                        </td>
                        <td>
                          <div className="viewUser">
                            <h5>3 items</h5>
                            <p className="distanceMiles">
                              <img src={money} />
                              <span>$489.50</span>
                            </p>
                          </div>
                        </td>
                        <td>
                          <div className="viewUser">
                            <h5 className="bluehead">Expess delivery</h5>
                            <p className="distanceMiles">
                              <img src={watch} />
                              <span>Expess delivery</span>
                            </p>
                          </div>
                        </td>
                        <td>
                          <div className="viewUser">
                            <img src={rightarrow} />
                          </div>
                        </td>
                      </div>
                      <div className="reviewContent">
                        <td>
                          <div className="viewUser">
                            <h5>Rebecca R. Russell</h5>
                            <p className="distanceMiles">
                              <img src={locdelivery} />
                              <span>2.5 miles</span>
                            </p>
                          </div>
                        </td>
                        <td>
                          <div className="viewUser">
                            <h5>3 items</h5>
                            <p className="distanceMiles">
                              <img src={money} />
                              <span>$489.50</span>
                            </p>
                          </div>
                        </td>
                        <td>
                          <div className="viewUser">
                            <h5 className="bluehead">Expess delivery</h5>
                            <p className="distanceMiles">
                              <img src={watch} />
                              <span>Expess delivery</span>
                            </p>
                          </div>
                        </td>
                        <td>
                          <div className="viewUser">
                            <img src={rightarrow} />
                          </div>
                        </td>
                      </div>
                      <div className="reviewContent">
                        <td>
                          <div className="viewUser">
                            <h5>Rebecca R. Russell</h5>
                            <p className="distanceMiles">
                              <img src={locdelivery} />
                              <span>2.5 miles</span>
                            </p>
                          </div>
                        </td>
                        <td>
                          <div className="viewUser">
                            <h5>3 items</h5>
                            <p className="distanceMiles">
                              <img src={money} />
                              <span>$489.50</span>
                            </p>
                          </div>
                        </td>
                        <td>
                          <div className="viewUser">
                            <h5 className="bluehead">Expess delivery</h5>
                            <p className="distanceMiles">
                              <img src={watch} />
                              <span>Expess delivery</span>
                            </p>
                          </div>
                        </td>
                        <td>
                          <div className="viewUser">
                            <img src={rightarrow} />
                          </div>
                        </td>
                      </div>
                      <div className="reviewContent">
                        <td>
                          <div className="viewUser">
                            <h5>Rebecca R. Russell</h5>
                            <p className="distanceMiles">
                              <img src={locdelivery} />
                              <span>2.5 miles</span>
                            </p>
                          </div>
                        </td>
                        <td>
                          <div className="viewUser">
                            <h5>3 items</h5>
                            <p className="distanceMiles">
                              <img src={money} />
                              <span>$489.50</span>
                            </p>
                          </div>
                        </td>
                        <td>
                          <div className="viewUser">
                            <h5 className="bluehead">Expess delivery</h5>
                            <p className="distanceMiles">
                              <img src={watch} />
                              <span>Expess delivery</span>
                            </p>
                          </div>
                        </td>
                        <td>
                          <div className="viewUser">
                            <img src={rightarrow} />
                          </div>
                        </td>
                      </div>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
            <div className="col-md-6 ">
              <div className="orderExpress">
                <div className="ReviewHead">
                  <h3>Order#WXCOO1256</h3>
                  <span>August 30, 2022</span>
                </div>
                <div className="orderDetails_">
                  <div className="userDetail">
                    <img src={userimg} />
                    <div className="userContent">
                      <h3>Rebecca R. Russell</h3>
                      <h6>1480 Bassel Street, New Orleans, LA 70113</h6>
                    </div>
                  </div>
                  <div className="userDetail">
                    <img src={exdelivery} />
                    <div className="userContent">
                      <h3 class="blueText_">Express Delivery</h3>
                      <h6>Immediately</h6>
                    </div>
                  </div>
                </div>
                <div className="MapOrders">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d96485.45894957401!2d-72.83509575903798!3d40.912005312792395!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89e85e2c0949dc13%3A0x1f8329c3de1c24ee!2sCalverton%2C%20NY%2C%20USA!5e0!3m2!1sen!2sin!4v1667997278940!5m2!1sen!2sin"
                    width="573"
                    height="700"
                    frameBorder="0"
                    border-radius="10px"
                    style={{ border: 0, borderRadius:'10px'}}
                    allowFullScreen=""
                    aria-hidden="false"
                    tabIndex="0"
                  />{" "}
                  <div className="deliveryStatus">
                    <div className="statusHead" onClick={() => handleClick(false)}>
                        <div className="statusLeft">
                            <h3>Order status</h3>
                            <h6>Cancelled</h6>
                        </div>
                        <img src={rightarrow} onClick=""/>
                    </div>
                    <div className={orderStatus ? 'statusBody addClass' : "statusBody"}>
                      <div className="unselectedCode">
                        <div className="codeLeft">
                          <img src={unselected} alt="unselected"/>
                          <h5 className="unselectcode">Return CODE</h5>
                        </div>
                        <div className="codeRight">
                          <h3>659X</h3>
                        </div>
                      </div>
                      <div className="readyPickupstatus">
                        <figure><img src={pickuporder} alt="cancel"/></figure>
                        <article>
                          <h3>Return to Shop</h3>
                          <div className="returnshop">
                            <img src={storelogo} alt="storename"/>
                            <div className="storeInfo">
                                <h5>Store nbame</h5>
                                <h6>1222 Tully Street,Detroit, MI 48227</h6>
                            </div>
                          </div>
                        </article>
                      </div>
                      <div className="readyPickupstatus">
                        <figure><img src={cancleorder} alt="cancel"/></figure>
                        <article>
                          <h3>Cancelled</h3>
                          <h6>waiting time 12 mins</h6>
                        </article>
                      </div>
                      <div className="readyPickupstatus">
                        <figure><img src={pickuporder} alt="pickup"/></figure>
                        <article>
                          <h3>Next to your block</h3>
                          <h6>with in 10 minutes</h6>
                        </article>
                      </div>
                      <div className="readyPickupstatus">
                        <figure><img src={verifyorder} alt="verifyorder"/></figure>
                        <article>
                          <h3>Product Pickup</h3>
                          <h6>with in 10 minutes</h6>
                        </article>
                      </div>
                      <div className="readyPickupstatus">
                        <figure><img src={pickuporder} alt="pickup"/></figure>
                        <article>
                          <h3>Assign Driver</h3>
                          <h6>with in 10 minutes</h6>
                        </article>
                      </div>
                      <div className="readyPickupstatus">
                        <figure><img src={pickuporder} alt="pickup"/></figure>
                        <article>
                          <h3>Ready to pickup</h3>
                          <h6>with in 10 minutes</h6>
                        </article>
                      </div>
                      <div className="readyPickupstatus">
                        <figure><img src={pickuporder} alt="pickup"/></figure>
                        <article>
                          <h3>Order accepted</h3>
                          <h6>with in 10 minutes</h6>
                        </article>
                      </div>
                    </div>
                  </div>  
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default ReadyPickup;
