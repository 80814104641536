import React,{useState} from 'react'
import { Link } from 'react-router-dom'
import { marlboro } from '../../../utilities/images'
import CustomModal from "../../components/shared/CustomModal";
import CheckoutModal from './CheckoutModal';

const DashboardProduct = () => {
    let data = [1, 2, 3, 4, 5, 6]
    const [key, setKey] = useState(Math.random());
    const [modalDetail, setModalDetail] = useState({
        show: false,
        title: "",
        flag: ""
    });
    const handleOnCloseModal = () => {
        setModalDetail({
            show: false,
            title: "",
            flag: ""
        });
        setKey(Math.random());
    }
    return (
        <>
            <div className="">
                <div className="row">
                    {
                        data.length > 0 ?
                            <>
                                {
                                    data.map((val, index) => {
                                        return (
                                            <div className="col-md-6 col-lg-4 custoSpc" key={index}>
                                                <div className="main_marlborobox">
                                                    <div className="marlborobox_head">
                                                        <img src={marlboro} alt="icon" className="img-fluid productImg" />
                                                        <div className="">
                                                            <h5 className="marlboro_heading">Marlboro Red</h5>
                                                            <span className="marlboro_subheading">Marlboro</span>
                                                        </div>
                                                    </div>
                                                    <div className="mt-2">
                                                        <p className="subtext_">Size</p>
                                                        <div className="btn-group mt-2">
                                                            <Link to="#" className="innerbtns active">Carton</Link>
                                                            <Link to="#" className="innerbtns" onClick={() => { setModalDetail({ show: true, flag: 'singlepack' }); setKey(Math.random()); }}>Single Pack</Link>
                                                        </div>
                                                    </div>
                                                    <div className="mt-2 mb-3">
                                                        <p className="subtext_">Price</p>
                                                        <p className="price_"><s>$5.65</s> <span>$5.65</span></p>
                                                    </div>
                                                    <div className="marlborobox_footer">
                                                        <div>
                                                            <button className="digitBtn"><i className="las la-minus"
                                                                onclick="incrDecr(0)"></i></button>
                                                            <span className="numberValue" id="noOfvalue">0</span>
                                                            <button className="digitBtn"><i className="las la-plus"
                                                                onclick="incrDecr(1)"></i></button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </>
                            : ""
                    }

                </div></div>
                <CustomModal
                key={key}
                show={modalDetail.show}
                backdrop="static"
                showCloseBtn={false}
                isRightSideModal={false}
                mediumWidth={false}
                checkoutModal={true}
                header={
                    <div className='modalHeader_'>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="common">
                                    <p className="checkout_heading">
                                        {modalDetail.flag === 'updatePriceModal' ?
                                            <>
                                                <span>Update Price</span>
                                            </>
                                            :
                                            <>
                                                <span>Amount: </span>
                                                <span><sup> $</sup>382.75</span>
                                            </>}
                                    </p>
                                    <span className="crossIcon" onClick={() => handleOnCloseModal()}>
                                        <i className="fa fa-times" aria-hidden="true"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                child={modalDetail.flag === 'singlepack'
                    ?<CheckoutModal closeModal={() => handleOnCloseModal()}/>
                    :""
                        // End Update PriceModal
                }
                onCloseModal={() => handleOnCloseModal()}
            />

        </>
    )
}

export default DashboardProduct