import React from 'react'
import { useHistory } from 'react-router-dom'
import { User } from '../../../utilities/images'

const ConfirmLoginModal = () => {
    const history = useHistory()
    return (
        <div className="confirmPopupContainer">
                    <div className="contentContainer_">
                        <h5 className="contentHeading_ mb-4">Confirm your log in</h5>
                        <img src={User} className="img-fluid" alt="user" />
                        <div className="userDetais">
                            <div className="userValues">
                                <p className="userInnerTxt mb-2">Millicent C. Edward</p>
                                <p className="userInnerTxt mb-2">ID:256913</p>
                            </div>
                            <p className="subtext_">Today Wednesday 11 Aug 2022
                                <br />
                                Time 3:25 pm
                            </p>
                            <button className="primary_btn mt-4" onClick={()=>history.push('/dashboard')}>Continue</button>
                        </div>
                    </div>
        </div>
    )
}

export default ConfirmLoginModal